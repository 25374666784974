.uborder{
    border: 1px solid red;
}
.Pronto_Container{
    z-index: 30px;
}
// *{
//     border: 1px solid pink;
// }

.pricing-card{
    border: 1px solid #232746;
}


  .gradient-box {
    $border: 2px;
    color: #FFF;
    background: #000;
    background-clip: padding-box; /* !importanté */
    border: solid $border transparent; /* !importanté */
    border-radius: 1em;
  
    &:before {
      content: '';
      position: absolute;
      top: 0; right: 0; bottom: 0; left: 0;
      z-index: -1;
      margin: -$border; /* !importanté */
      border-radius: inherit; /* !importanté */
      background: linear-gradient(to top left, #F767F2, #8290FF, #F767F2);
    }
  }